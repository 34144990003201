:root {
  --animate-delay: 2s;
}

.App {
  font-family: 'Fjalla One', sans-serif;
}

nav {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    li {
      list-style-type: none;
      margin: 0 0 5px 0;
      padding: 0;
      a {
        text-align: center;
        display: block;
        width: 200px;
        height: 40px;
        line-height: 40px;
        margin: 0;
        padding: 0;
        color: black;
        text-decoration: none;
        text-transform: uppercase;
        transition: all 0.2s ease-in-out;
        &:hover {
          background: black;
          color: white;
        }
      }
    }
  }
}

.app-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: black;
  color: white;
  height: 60px;
  transition: all 0.3s ease-out;
  position: relative;
  @media screen and (min-width: 768px) {
    height: 80px;
  }
  @media screen and (min-width: 960px) {
    height: 100px;
  }
  @media screen and (min-width: 1200px) {
    height: 120px;
  }
  .app-logo {
    margin-left: 40px;
    font-size: 18px;
    transition: all 0.3s ease-out;
    font-weight: normal;
    padding-top: 25px;
    @media screen and (min-width: 768px) {
      font-size: 22px;
    }
    @media screen and (min-width: 960px) {
      font-size: 27px;
    }
    @media screen and (min-width: 1200px) {
      font-size: 36px;
    }
  }
}

.scroll-to-top {
  position: fixed;
  left: 20px;
  bottom: 20px;
  height: 48px;
  width: 48px;
  line-height: 48px;
  text-align: center;
  border-radius: 50%;
  box-shadow: rgba(black, 0.25) 0 0 5px;
  color: black;
  border: 1px solid rgba(black, 0);
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  z-index: 10;
  background: white;
  &:hover {
    border-color: white;
    color: white;
    background: rgba(black, 1);
  }
}

.home-hero {
  background: black;
  color: white;
  height: 240px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease-out;
  height: calc(100vh - 60px);
  @media screen and (min-width: 768px) {
    // height: 360px;
  }
  @media screen and (min-width: 960px) {
    // height: 480px;
  }
  @media screen and (min-width: 1200px) {
    // height: 600px;
  }
  blockquote {
    margin: 0;
    padding: 0;
    width: 75%;
    @media screen and (min-width: 768px) {
      width: 60%;
    }
    @media screen and (min-width: 960px) {
      // height: 480px;
    }
    @media screen and (min-width: 1200px) {
      width: 65%;
    }
    p,
    h1 {
      margin-top: 0;
      font-size: 18px;
      line-height: 1.4;
      font-weight: normal;
      @media screen and (min-width: 768px) {
        font-size: 24px;
        line-height: 1.5;
      }
      @media screen and (min-width: 960px) {
        font-size: 36px;
      }
      @media screen and (min-width: 1200px) {
        font-size: 48px;
      }
    }
  }
}

.who-we-are {
  padding: 40px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  @media screen and (min-width: 768px) {
    padding: 60px 0;
  }
  @media screen and (min-width: 960px) {
    padding: 80px 0;
  }
  @media screen and (min-width: 1200px) {
    padding: 100px 0;
  }
  .section-header {
    background: black;
    color: white;
    width: 40%;
    border-top-right-radius: 50px;
    padding-top: 40px;
    padding-bottom: 40px;
    text-align: center;
    .section-icon {
      width: 64px;
      height: 64px;
      margin: 0 auto;
      @media screen and (min-width: 768px) {
        width: 96px;
        height: 96px;
      }
      @media screen and (min-width: 960px) {
        width: 128px;
        height: 128px;
      }
      @media screen and (min-width: 1200px) {
        height: 150px;
        width: 150px;
      }
      img {
        max-width: 100%;
      }
    }
    .section-title {
      margin: 0;
      padding: 0;
      font-size: 20px;
      letter-spacing: 2px;
      font-weight: normal;
      @media screen and (min-width: 768px) {
        font-size: 22px;
      }
      @media screen and (min-width: 960px) {
        font-size: 24px;
      }
      @media screen and (min-width: 1200px) {
        font-size: 27px;
      }
    }
  }
  .entry {
    width: 60%;
    padding-left: 40px;
    padding-right: 40px;
    @media screen and (min-width: 768px) {
      padding: 0 60px;
    }
    @media screen and (min-width: 960px) {
      padding: 0 80px;
    }
    @media screen and (min-width: 1200px) {
      padding: 0 100px 0 120px;
    }
    p {
      font-size: 12px;
      margin: 0;
      padding: 0;
      text-align: justify;
      line-height: 1.5;
      @media screen and (min-width: 768px) {
        font-size: 14px;
        line-height: 1.6;
      }
      @media screen and (min-width: 960px) {
        font-size: 18px;
      }
      @media screen and (min-width: 1200px) {
        font-size: 20px;
      }
    }
  }
}

.our-services {
  padding: 40px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row-reverse;
  @media screen and (min-width: 768px) {
    padding: 60px 0;
  }
  @media screen and (min-width: 960px) {
    padding: 80px 0;
  }
  @media screen and (min-width: 1200px) {
    padding: 100px 0;
  }
  .section-header {
    background: black;
    color: white;
    width: 40%;
    border-top-left-radius: 50px;
    padding-top: 40px;
    padding-bottom: 40px;
    text-align: center;
    .section-icon {
      width: 64px;
      height: 64px;
      margin: 0 auto;
      @media screen and (min-width: 768px) {
        width: 96px;
        height: 96px;
      }
      @media screen and (min-width: 960px) {
        width: 128px;
        height: 128px;
      }
      @media screen and (min-width: 1200px) {
        height: 150px;
        width: 150px;
      }
      img {
        max-width: 100%;
      }
    }
    .section-title {
      margin: 10px 0 0 0;
      padding: 0;
      font-size: 20px;
      letter-spacing: 2px;
      font-weight: normal;
      @media screen and (min-width: 768px) {
        font-size: 22px;
      }
      @media screen and (min-width: 960px) {
        font-size: 24px;
      }
      @media screen and (min-width: 1200px) {
        font-size: 27px;
      }
    }
  }
  .entry {
    width: 60%;
    padding-left: 40px;
    padding-right: 40px;
    @media screen and (min-width: 768px) {
      padding: 0 60px;
    }
    @media screen and (min-width: 960px) {
      padding: 0 80px;
    }
    @media screen and (min-width: 1200px) {
      padding: 0 100px 0 120px;
    }
    p {
      font-size: 12px;
      margin: 0;
      padding: 0;
      text-align: justify;
      line-height: 1.5;
      @media screen and (min-width: 768px) {
        font-size: 14px;
        line-height: 1.6;
      }
      @media screen and (min-width: 960px) {
        font-size: 18px;
      }
      @media screen and (min-width: 1200px) {
        font-size: 20px;
      }
    }
  }
}

.clients-loved-these {
  padding: 0 40px;
  @media screen and (min-width: 768px) {
    padding: 0 60px;
  }
  @media screen and (min-width: 960px) {
    padding: 0 80px;
  }
  @media screen and (min-width: 1200px) {
    padding: 0 100px;
  }
  .love-list {
    margin: 0;
    padding: 0 0 20px 0;
    > div > div {
      &:nth-of-type(odd) .list-item {
        margin-left: 50%;
      }
      &:nth-of-type(even) .list-item {
      }
    }
    .list-item {
      font-size: 27px;
      display: flex;
      box-sizing: border-box;
      flex-wrap: nowrap;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 50%;
      .icon-and-number {
        display: flex;
        align-items: center;
        justify-content: center;
        .item-number {
          text-align: center;
        }
        .icon-wrap {
          img {
            height: 64px;
            width: auto;
            @media screen and (min-width: 768px) {
              height: 128px;
            }
            @media screen and (min-width: 960px) {
              height: 160px;
            }
            @media screen and (min-width: 768px) {
              height: 200px;
            }
          }
        }
      }
      .content-wrap {
        display: flex;
        .item-details {
          h3 {
            text-align: center;
            font-size: 14px;
            margin: 0;
            padding: 0;
            font-weight: normal;
            @media screen and (min-width: 768px) {
              font-size: 16px;
            }
            @media screen and (min-width: 960px) {
              font-size: 18px;
            }
            @media screen and (min-width: 1200px) {
              font-size: 24px;
            }
          }
        }
      }
      p {
        text-align: justify;
        font-size: 11px;
        font-weight: normal;
        font-family: sans-serif;
        @media screen and (min-width: 768px) {
          font-size: 13px;
        }
        @media screen and (min-width: 960px) {
          font-size: 14px;
        }
        @media screen and (min-width: 1200px) {
          font-size: 16px;
        }
      }
    }
  }
}

.founders {
  .image-grid {
    display: flex;
    .image-wrapper {
      flex: 1;
      filter: sepia(0);
      -webkit-filter: sepia(0);
      transition: all 0.75s ease-in-out;
      &:hover {
        filter: sepia(0.3);
        -webkit-filter: sepia(0.3);
      }
      img {
        max-width: 100%;
      }
    }
  }

  .section-header {
    background: black;
    display: inline-block;
    margin-top: 20px;
    .section-title {
      color: white;
      margin: 0;
      padding: 15px 40px;
      font-size: 18px;
      font-weight: normal;
      letter-spacing: 2px;
      @media screen and (min-width: 768px) {
        font-size: 20px;
      }
      @media screen and (min-width: 960px) {
        font-size: 22px;
      }
      @media screen and (min-width: 1200px) {
        font-size: 24px;
      }
    }
  }

  .entry {
    margin: 0;
    padding: 20px 40px;
    text-align: justify;
    font-size: 12px;
    line-height: 1.5;
    @media screen and (min-width: 768px) {
      font-size: 14px;
    }
    @media screen and (min-width: 960px) {
      font-size: 16px;
    }
    @media screen and (min-width: 1200px) {
      font-size: 18px;
    }
  }
}

.what-we-live-by {
  .section-header {
    margin-top: 20px;
    text-align: right;
    .section-title {
      background: black;
      color: white;
      margin: 0;
      padding: 15px 40px;
      display: inline-block;
      font-size: 18px;
      font-weight: normal;
      letter-spacing: 2px;
      @media screen and (min-width: 768px) {
        font-size: 20px;
      }
      @media screen and (min-width: 960px) {
        font-size: 22px;
      }
      @media screen and (min-width: 1200px) {
        font-size: 24px;
      }
    }
  }
  .icons-list-wrapper {
    margin-top: 40px;
    padding: 0 40px;
    .icons-list {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      list-style: none;
      margin: 0;
      padding: 0;
      .list-item {
        flex: 1;
        width: 30%;
        text-align: center;
        margin: 0;
        padding: 0;
        img {
          display: block;
          max-width: 100%;
          margin: 0 auto 10px auto;
          max-height: 48px;
          width: auto;
          max-width: 48px;
          @media screen and (min-width: 768px) {
            max-height: 64px;
            max-width: 80px;
          }
          @media screen and (min-width: 960px) {
            max-height: 72px;
            max-width: 100px;
          }
          @media screen and (min-width: 1200px) {
            max-height: 90px;
            max-width: 120px;
          }
        }
        strong {
          text-align: center;
          font-size: 16px;
          font-weight: normal;
          letter-spacing: 1.5px;
          @media screen and (min-width: 768px) {
            font-size: 16px;
          }
          @media screen and (min-width: 960px) {
            font-size: 18px;
          }
          @media screen and (min-width: 1200px) {
            font-size: 20px;
          }
        }
      }
    }
  }
}

.why-choose-us {
  background: black;
  color: white;
  border-top-right-radius: 30px;
  position: relative;
  @media screen and (min-width: 768px) {
    border-top-right-radius: 40px;
    padding-bottom: 10px;
  }
  @media screen and (min-width: 960px) {
    border-top-right-radius: 50px;
    padding-bottom: 20px;
  }
  @media screen and (min-width: 1200px) {
    border-top-right-radius: 60px;
    padding-bottom: 30px;
  }
  &:before {
    content: '';
    display: block;
    background: white;
    height: 2px;
    width: 50%;
    right: 0;
    top: 62px;
    position: absolute;
    @media screen and (min-width: 768px) {
      height: 3px;
      top: 82px;
    }
    @media screen and (min-width: 960px) {
      height: 4px;
      top: 110px
    }
    @media screen and (min-width: 1200px) {
      height: 5px;
      top: 136px;
    }
  }
  &:after {
    content: '';
    display: block;
    background: white;
    height: 80px;
    width: 80px;
    left: 0;
    bottom: 0;
    position: absolute;
    border-top-right-radius: 30px;
    @media screen and (min-width: 768px) {
      height: 100px;
      width: 100px;
      border-top-right-radius: 40px;
    }
    @media screen and (min-width: 960px) {
      height: 120px;
      width: 120px;
      border-top-right-radius: 50px;
    }
    @media screen and (min-width: 1200px) {
      height: 140px;
      width: 140px;
      border-top-right-radius: 60px;
    }
  }
  .section-header {
    display: flex;
    align-items: center;
    padding-top: 40px;
    margin-top: 40px;
    @media screen and (min-width: 768px) {
      padding-top: 60px;
    }
    @media screen and (min-width: 960px) {
      padding-top: 80px;
    }
    @media screen and (min-width: 1200px) {
      padding-top: 100px;
    }
    .section-title {
      text-transform: uppercase;
      font-size: 16px;
      text-align: right;
      width: calc(50% - 40px);
      font-weight: normal;
      letter-spacing: 2px;
      @media screen and (min-width: 768px) {
        font-size: 18px;
        width: calc(50% - 80px);
      }
      @media screen and (min-width: 960px) {
        font-size: 24px;
        width: calc(50% - 120px);
      }
      @media screen and (min-width: 1200px) {
        font-size: 30px;
        width: calc(50% - 160px);
      }
    }
  }
  .section-content {
    display: flex;
    align-items: center;
    padding: 40px;
    position: relative;
    .icon-wrap {
      text-align: right;
      width: 50%;
      img {
        max-width: 100px;
        margin-right: 40px;
        @media screen and (min-width: 768px) {
          max-width: 128px;
          margin-right: 80px;
        }
        @media screen and (min-width: 960px) {
          max-width: 160px;
          margin-right: 120px;
        }
        @media screen and (min-width: 1200px) {
          max-width: 200px;
          margin-right: 160px;
        }
      }
    }
    .details-wrap {
      width: 50%;
      ul {
        margin: 0;
        padding: 0;
        list-style: none;
        li {
          h4 {
            margin: 0;
            padding: 0;
            font-size: 14px;
            font-weight: normal;
            letter-spacing: 1.5px;
            @media screen and (min-width: 768px) {
              font-size: 16px;
            }
            @media screen and (min-width: 960px) {
              font-size: 18px;
            }
            @media screen and (min-width: 1200px) {
              font-size: 20px;
            }
          }
          p {
            font-size: 12px;
            font-family: sans-serif;
            @media screen and (min-width: 768px) {
              font-size: 13px;
            }
            @media screen and (min-width: 960px) {
              font-size: 14px;
            }
            @media screen and (min-width: 1200px) {
              font-size: 16px;
            }
          }
        }
      }
    }
  }
}

.app-footer {
  background: white;
  color: black;
  padding: 40px 0 40px 40px;
  display: flex;
  justify-content: space-between;
  @media screen and (min-width: 768px) {
    padding: 80px 0 80px 80px;
  }
  @media screen and (min-width: 960px) {
    padding: 120px 0 120px 120px;
  }
  @media screen and (min-width: 1200px) {
    padding: 160px 0 160px 160px;
  }
  .contact-details {
    address {
      font-style: normal;
      .company-name {
        font-weight: normal;
      }
      p {
        font-family: sans-serif;
        line-height: 1.5;
      }
      .email {
        display: block;
        font-family: sans-serif;
        line-height: 1.5;
      }
      .phone {
        display: block;
        font-family: sans-serif;
        line-height: 1.5;
      }
    }
  }
  .section-header {
    .section-title {
      background: black;
      color: white;
      margin: 0;
      padding: 15px 40px;
      display: inline-block;
      font-size: 18px;
      font-weight: normal;
      letter-spacing: 2px;
      @media screen and (min-width: 768px) {
        font-size: 20px;
      }
      @media screen and (min-width: 960px) {
        font-size: 22px;
      }
      @media screen and (min-width: 1200px) {
        font-size: 24px;
      }
    }
  }
}